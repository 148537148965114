import React from "react";
import Header from "../Header/Header";
import './Hero.css'
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import heart from '../../assets/heart.png';
import calories from '../../assets/calories.png';
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'

let Hero=()=>{
    const transition={type :'spring', duration :3}
    return(
        <>
       
  <div className="hero" id="Home">
  <div className="blur H-blur"></div>
        <div className="left-h">
         <Header/>


  {/* ads sectio */}
         <div className="bestAds" >
            <motion.div 
            initial={{left:'238px'}} 
             whileInView={{left:'8px'}}
             transition={{...transition, type:'tween'}}
              className="ads1"></motion.div>
            <span> The Best fitness club in the City </span>
         </div>
    
     {/* hero text  */}

     <div className="hero-text">
            <div>
                <span className="stroke-text">SHAPE </span>
                <span>YOUR</span>
            </div>
            <div>
                <span>IDEAL BODY</span>
            </div>
            <div>
        Our Gym is Your Gym
       Kick your feet up! With a gym designed around you  we think you’ll love it here.
        </div>
        </div>

        {/* figures */}
        <div className="figures">
            <div><span><NumberCounter start={100} end={144} delay='4' preFix='+'/></span><span>Expert Coaches</span></div>
            <div><span><NumberCounter start={800} end={978} delay='4' preFix='+'/></span><span>Members Joined</span></div>
            <div><span><NumberCounter start={0} end={50} delay='4' preFix='+'/></span><span>Fitness Programs</span></div>
        </div>

     {/* hero-btn  */}
     <div class="hero-btn">
        <button class="btn" >Get Started</button>
        <button class="btn" >Learn More</button>
     </div>
      
</div>

       
        <div className="right-h">
         <button className="btn" alt="">Join Now</button>

         <motion.div 
           initial={{right:'-1rem'}} 
           whileInView={{right:'4rem'}}
           transition={transition}
           className="heart-rate">
         <img src={heart} alt="" />
         <span>Heart Rate</span> <span>116 bmp</span>
         </motion.div>

         <img src={hero_image} alt="" className="hero_image" />
         <motion.img
         initial={{right:'11rem'}} 
         whileInView={{right:'20rem'}}
         transition={transition}
          src={hero_image_back} alt="" className="hero_image_back" />

         <motion.div
         initial={{right:'38rem'}} 
         whileInView={{right:'28rem'}}
         transition={transition}
          className="calories" >
            <img src={calories} alt="" />
            <div>
            <span>Calories</span><span>220 Kcal</span>
         </div>
         </motion.div>
        </div>
  </div>
        </>
    )
}
export default Hero