import "./Reason.css";
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/image3.png";
import image4 from "../../assets/image4.png";
import nb from "../../assets/nb.png";
import adidas from "../../assets/adidas.png";
import nike from "../../assets/nike.png";
import tick from "../../assets/tick.png";


const Reason = () => {
  return (
    <>
      <div className="reason" id="Whyus">
        <div className="left-r">
          <img src={image1} alt="" />
          <img src={image2} alt="" />
          <img src={image3} alt="" />
          <img src={image4} alt="" />
        </div>

        <div className="right-r">
            <span>some reason</span>
            <div className="">
                <span className="stroke-text">Why </span>
                <span> Choose us?</span>
            </div>
            <div className="details-r">
                <div><img src={tick} alt="" /> <span>Over !40+ Expert Coachs</span></div>
                <div><img src={tick} alt="" /> <span>Train smarter and faster then Before</span></div>
                <div><img src={tick} alt="" /> <span>1 Free program For New Member</span></div>
                <div><img src={tick} alt="" /> <span></span>Reliable Partner</div>
            </div>
            <span style={{color:"gray",fontWeight:"normal" }}>
                Our Partners
            </span>
            <div className="partner">
                <img src={nb} alt="" />
                <img src={adidas} alt="" />
                <img src={nike} alt="" />
            </div>
        </div>
      </div>
    </>
  );
};
export default Reason;
