import { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'
const Join =()=> {
    const form= useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_2msxi7g', 'template_skvb4ft', form.current, '-2dDyovR5xPRvCqpF')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
    
    return(
        <>
        <div className="join">
            <div className="left-j">
                <div>
                    <span className='stroke-text'>READY TO </span>
                    <span>LEVEL UP</span>
                </div>
                <div>
                    <span>YOUR BODY</span>
                    <span className='stroke-text'> WITH US</span>
                </div>
            </div>
            <div className="right-j">
                <form ref={form} className='email-container' onSubmit={sendEmail}>
                    <input type="email" name='user-email' placeholder='Enter Your Email Address' />
                    <button className='btn btn-join'>Join Now</button>
                </form>
            </div>
        </div>
        </>
    )
}
export default Join