import logo from "../../assets/logo.png";
import bar from "../../assets/bars.png";
import { Link } from "react-scroll";
import "./Header.css";
import { useState } from "react";

let Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [MenuOpened, setMenuOpened] = useState(false);
  return (
    <>
      <div className="header">
        <img src={logo} alt="" className="logo" />

        {MenuOpened === false && mobile === true ? (
          <div onClick={() => setMenuOpened(true)} className="bar">
            <img
              src={bar}
              alt=""
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
          </div>
        ) : (
          <ul className="header-menu">
            <li onClick={() => setMenuOpened(false)}>
            <Link
                onClick={() => setMenuOpened(false)}
                to="Program"
                span={true}
                smooth={true}  >
                Program
              </Link></li>
            <li onClick={() => setMenuOpened(false)}>
            <Link
                onClick={() => setMenuOpened(false)}
                to="Home"
                span={true}
                smooth={true}  >
                Home
              </Link></li>
            <li onClick={() => setMenuOpened(false)}>
            <Link
                onClick={() => setMenuOpened(false)}
                to="Whyus"
                span={true}
                smooth={true}  >
               Why us
              </Link></li>
            <li onClick={() => setMenuOpened(false)}>
            <Link
                onClick={() => setMenuOpened(false)}
                to="Plans"
                span={true}
                smooth={true}  >
                Plans
              </Link></li>
            <li onClick={() => setMenuOpened(false)}>
              <Link
                onClick={() => setMenuOpened(false)}
                to="Testiimonls"
                span={true}
                smooth={true}  >
                Testimonials
              </Link>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default Header;
