
import './Footer.css'
import insta from '../../assets/instagram.png'
import linkedin from '../../assets/linkedin.png'
import github from '../../assets/github.png'
import logo from '../../assets/logo.png'

const Footer = () => {
    return(
        <>
        <div className="Footer-container">
        {/* <hr /> */}
        <div className="Footer">
          
            <div className="social">
                <img src={insta} alt="" />
                <img src={linkedin} alt="" />
                <img src={github} alt="" />
            </div>
            <div className="logo-f">
                <img src={logo} alt="" />
            </div>
        </div>
        </div>
        </>
    )
}
export default Footer