import './Program.css'
import {programsData} from '../../data/programsData'
import RightArrow from '../../assets/rightArrow.png'

const Program =()=>{
    return(
        <>
        <div className="program" id="Program">
            <div className="program-header">
                <span className='stroke-text'>Explore Our</span>
                <span>Programs</span>
                <span className='stroke-text'>To Shape You</span>
            </div>

            <div className="program-categories">
                {programsData.map((program)=>(
                    <div className="program-category">
                        {program.image}
                        <span>{program.heading}</span>
                        <span>{program.details}</span>
                        <div className="join"><span>JOIN Now</span><img src={RightArrow} alt="" /></div>
                    </div>
                ))}
            </div>
            
        </div>
        </>
    )
}
export default Program